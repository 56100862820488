body {
  font-family: Sans-serif;
  background: #fff url("../images/bg_nofade.png") repeat;
  color: #444;
}

img {
  border: none;
}

.content-block {
  border: 1px solid #bbb;
  background: #eee;
  margin-top: 1em;
  padding: 1em;
}

#navbar {
  border: 1px solid #BBB;
  background-color: #3B5998;
  color: #fff;
  background: #3B5998;
  padding: 1px;
}

#navbar ul.navlist {
  list-style-type: none;
  margin: 0px;
  padding: 1px;
  text-align: center;
}

.navlist li {
  line-height: 200%;
  list-style-type: none;
  display: inline;
  padding: 3px;
  margin-bottom: 5px;
  margin-left: 0px;
  margin-right: 0px;
}


.navlist form {
  margin: 0px;
  padding: 0px;
}

#navbar a {
  font-size: 110%;
  font-weight: bold;
  padding: 2px 9px;
  text-decoration: none;
  color: #afc0e2 !important;
}

#navbar a:hover {
  color: #fff !important;
  text-decoration: none;
}

#subnavbar {
  margin: 0px 40px 10px 40px;
  border: 1px solid #BBB;
  background-color: #ddd;
  color: #000;
  padding: 1px;
  text-align: center;
  -moz-border-radius: 5px;
}

#subnavbar ul {
  margin: 0px;
  margin-left: -10px;
}

#subnavbar a {
  font-size: 110%;
  font-weight: bold;
  padding: 2px 9px;
  text-decoration: none;
}

#donate_buttons {
  text-align: center;
}

#donate_buttons a {
  text-decoration: none;
}

#donate_buttons .text {
  font-weight: bold;
  color: #000000 !important;
}

#donate_buttons .middle-aligned {
  vertical-align: middle;
}

#donate_buttons form {
  display: inline;
  margin-bottom: 0px;
}

#donate_buttons .donate_element {
  margin-left: 5px;
  margin-right: 5px;
  display: inline;
}

#donate_buttons input {
  margin-bottom: 0;
}

#footerbar {
  color: #888;
  text-align: center;
}
#footerbar a {
  color: #888;
}

.pagetitle {
  margin-top: 0.5em;
  text-align: center;
}

pre, code, .code {
  background-color: #e0e0e0;
  font-family: monospace;
}
